export function finalResult() {
  // TODO: データ構造や内容によって命名を再考する
  const data = [
    { rank: 1, characterName: 'ルック', sumOfVotes: 543 },
    { rank: 2, characterName: 'テッド', sumOfVotes: 466 },
    { rank: 3, characterName: 'フリック', sumOfVotes: 328 },
    { rank: 4, characterName: '幻水4主人公（4様）', sumOfVotes: 308 },
    { rank: 5, characterName: '幻水2主人公（2主）', sumOfVotes: 275 },
    { rank: 6, characterName: 'グレミオ', sumOfVotes: 189 },
    { rank: 6, characterName: 'ビクトール', sumOfVotes: 189 },
    {
      rank: 7,
      characterName: 'ジョウイ・アトレイド（ブライト）',
      sumOfVotes: 185,
    },
    { rank: 8, characterName: 'ナナミ', sumOfVotes: 155 },
    { rank: 9, characterName: 'マイクロトフ', sumOfVotes: 137 },
    { rank: 10, characterName: 'ルカ・ブライト', sumOfVotes: 132 },
    { rank: 11, characterName: 'カミュー', sumOfVotes: 130 },
    { rank: 12, characterName: 'ササライ', sumOfVotes: 111 },
    { rank: 13, characterName: 'シーナ', sumOfVotes: 105 },
    { rank: 14, characterName: 'フッチ', sumOfVotes: 102 },
    {
      rank: 15,
      characterName: 'ナッシュ・ラトキエ（クロービス）',
      sumOfVotes: 99,
    },
    { rank: 16, characterName: 'クライブ', sumOfVotes: 97 },
    { rank: 17, characterName: 'シード', sumOfVotes: 89 },
    { rank: 18, characterName: 'シュウ', sumOfVotes: 83 },
    { rank: 19, characterName: 'スタリオン', sumOfVotes: 82 },
    { rank: 19, characterName: 'ゲオルグ・プライム', sumOfVotes: 82 },
    { rank: 20, characterName: 'シエラ・ミケーネ', sumOfVotes: 67 },
    { rank: 21, characterName: '幻水5主人公（王子）', sumOfVotes: 62 },
    { rank: 22, characterName: 'トーマス', sumOfVotes: 56 },
    { rank: 23, characterName: 'ゲド', sumOfVotes: 55 },
    { rank: 24, characterName: 'キニスン', sumOfVotes: 51 },
    { rank: 25, characterName: 'カイル', sumOfVotes: 50 },
    { rank: 26, characterName: 'ビッキー', sumOfVotes: 49 },
    { rank: 27, characterName: 'ヒューゴ', sumOfVotes: 47 },
    { rank: 28, characterName: 'シーザー・シルバーバーグ', sumOfVotes: 44 },
    { rank: 29, characterName: 'クリン', sumOfVotes: 42 },
    { rank: 29, characterName: 'マッシュ・シルバーバーグ', sumOfVotes: 42 },
    { rank: 30, characterName: 'ヤム・クー', sumOfVotes: 38 },
    { rank: 30, characterName: 'セラ', sumOfVotes: 38 },
    { rank: 31, characterName: 'アルベルト・シルバーバーグ', sumOfVotes: 36 },
    { rank: 31, characterName: 'オデッサ・シルバーバーグ', sumOfVotes: 36 },
    { rank: 32, characterName: 'ネクロード', sumOfVotes: 35 },
    { rank: 33, characterName: 'クラウス・ウィンダミア', sumOfVotes: 34 },
    { rank: 33, characterName: 'スノウ・フィンガーフート', sumOfVotes: 34 },
    { rank: 34, characterName: 'クルガン', sumOfVotes: 33 },
    { rank: 35, characterName: 'クリス・ライトフェロー', sumOfVotes: 32 },
    { rank: 35, characterName: 'ユーバー', sumOfVotes: 32 },
    { rank: 36, characterName: 'ティアクライス主人公（団長）', sumOfVotes: 30 },
    { rank: 36, characterName: 'クレオ', sumOfVotes: 30 },
    { rank: 37, characterName: 'ロイ', sumOfVotes: 29 },
    { rank: 38, characterName: 'カスミ', sumOfVotes: 27 },
    { rank: 38, characterName: 'リヒャルト', sumOfVotes: 27 },
    { rank: 39, characterName: 'ジャック', sumOfVotes: 26 },
    { rank: 39, characterName: 'アルド', sumOfVotes: 26 },
    { rank: 39, characterName: 'ザムザ', sumOfVotes: 26 },
    { rank: 40, characterName: 'テンプルトン', sumOfVotes: 25 },
    { rank: 41, characterName: 'バレリア', sumOfVotes: 24 },
    { rank: 42, characterName: 'ペシュメルガ', sumOfVotes: 23 },
    { rank: 43, characterName: '炎の英雄', sumOfVotes: 21 },
    { rank: 43, characterName: 'オウラン', sumOfVotes: 21 },
    { rank: 44, characterName: 'パーシヴァル・フロイライン', sumOfVotes: 20 },
    { rank: 45, characterName: 'ヒックス', sumOfVotes: 19 },
    { rank: 46, characterName: 'ゼフォン', sumOfVotes: 17 },
    { rank: 46, characterName: 'リウ・シエン', sumOfVotes: 17 },
    { rank: 47, characterName: 'フィッチャー', sumOfVotes: 16 },
    { rank: 47, characterName: 'セシル', sumOfVotes: 16 },
    { rank: 47, characterName: 'レーヴン', sumOfVotes: 16 },
    { rank: 47, characterName: 'ロベルト', sumOfVotes: 16 },
    { rank: 47, characterName: 'カミーユ', sumOfVotes: 16 },
    { rank: 48, characterName: 'ミアキス', sumOfVotes: 15 },
    { rank: 48, characterName: 'ハーヴェイ', sumOfVotes: 15 },
    { rank: 48, characterName: 'ユージン', sumOfVotes: 15 },
    { rank: 48, characterName: 'サスケ', sumOfVotes: 15 },
    { rank: 48, characterName: 'キリル', sumOfVotes: 15 },
    { rank: 49, characterName: 'ジョー軍曹', sumOfVotes: 14 },
    { rank: 49, characterName: 'ボルス・レッドラム', sumOfVotes: 14 },
    { rank: 49, characterName: 'リオン', sumOfVotes: 14 },
    { rank: 49, characterName: 'メグ', sumOfVotes: 14 },
    { rank: 49, characterName: 'ジル・ブライト', sumOfVotes: 14 },
    { rank: 49, characterName: 'ルセリナ・バロウズ', sumOfVotes: 14 },
    { rank: 50, characterName: 'ルビィ', sumOfVotes: 13 },
    { rank: 50, characterName: 'キカ', sumOfVotes: 13 },
    { rank: 50, characterName: 'ヘルムート', sumOfVotes: 13 },
    { rank: 50, characterName: 'シロ', sumOfVotes: 13 },
    { rank: 50, characterName: 'シグルド', sumOfVotes: 13 },
    { rank: 50, characterName: 'ハンフリー・ミンツ', sumOfVotes: 13 },
    { rank: 50, characterName: 'レックナート', sumOfVotes: 13 },
    { rank: 50, characterName: 'シルビナ', sumOfVotes: 13 },
    { rank: 51, characterName: 'カーン・マリィ', sumOfVotes: 12 },
    { rank: 51, characterName: 'キバ・ウィンダミア', sumOfVotes: 12 },
    { rank: 51, characterName: 'ムクムク', sumOfVotes: 12 },
    {
      rank: 51,
      characterName: '紡がれし百年の時主人公（紡主）',
      sumOfVotes: 12,
    },
    { rank: 51, characterName: 'ムバル', sumOfVotes: 12 },
    { rank: 52, characterName: 'グレンシール', sumOfVotes: 11 },
    { rank: 52, characterName: 'リッチモンド', sumOfVotes: 11 },
    { rank: 52, characterName: 'ケネス', sumOfVotes: 11 },
    { rank: 52, characterName: 'アスアド', sumOfVotes: 11 },
    { rank: 52, characterName: 'サロメ・ハラス', sumOfVotes: 11 },
    { rank: 52, characterName: 'ヤール', sumOfVotes: 11 },
    { rank: 52, characterName: 'テンガアール', sumOfVotes: 11 },
    { rank: 52, characterName: 'ミリー', sumOfVotes: 11 },
    { rank: 52, characterName: 'レイチェル', sumOfVotes: 11 },
    { rank: 53, characterName: 'ミルイヒ・オッペンハイマー', sumOfVotes: 10 },
    { rank: 53, characterName: 'シグレ', sumOfVotes: 10 },
    { rank: 53, characterName: 'タイ・ホー', sumOfVotes: 10 },
    { rank: 53, characterName: 'ベルクート', sumOfVotes: 10 },
    { rank: 53, characterName: 'キルケ', sumOfVotes: 10 },
    {
      rank: 53,
      characterName: 'キルキス・シャナ・クエス・ラビアンカーナ',
      sumOfVotes: 10,
    },
    { rank: 53, characterName: 'ソロン・ジー', sumOfVotes: 10 },
    { rank: 53, characterName: 'ネリス', sumOfVotes: 10 },
    { rank: 54, characterName: 'エース', sumOfVotes: 9 },
    { rank: 54, characterName: 'シドニア', sumOfVotes: 9 },
    { rank: 54, characterName: 'ムーア', sumOfVotes: 9 },
    { rank: 54, characterName: 'エルザ', sumOfVotes: 9 },
    { rank: 54, characterName: 'アイン・ジード', sumOfVotes: 9 },
    { rank: 54, characterName: 'マナリル', sumOfVotes: 9 },
    { rank: 54, characterName: 'トロイ', sumOfVotes: 9 },
    { rank: 54, characterName: 'フレッド・マクシミリアン', sumOfVotes: 9 },
    { rank: 54, characterName: 'アレン', sumOfVotes: 9 },
    { rank: 54, characterName: 'バーツ', sumOfVotes: 9 },
    { rank: 54, characterName: 'ラミン', sumOfVotes: 9 },
    { rank: 54, characterName: 'トルワド・アルブレク', sumOfVotes: 9 },
    { rank: 54, characterName: 'ユーラム・バロウズ', sumOfVotes: 9 },
    { rank: 54, characterName: 'ジェイル', sumOfVotes: 9 },
    { rank: 54, characterName: 'パーン', sumOfVotes: 9 },
    { rank: 54, characterName: 'ジーン', sumOfVotes: 9 },
    { rank: 55, characterName: 'リドリー・ワイゼン', sumOfVotes: 8 },
    { rank: 55, characterName: 'フリード・Ｙ', sumOfVotes: 8 },
    { rank: 55, characterName: 'ジョアン', sumOfVotes: 8 },
    { rank: 55, characterName: 'ブラックマン', sumOfVotes: 8 },
    { rank: 55, characterName: 'ミーナ', sumOfVotes: 8 },
    { rank: 55, characterName: 'テオ・マクドール', sumOfVotes: 8 },
    { rank: 55, characterName: 'リヒャルトのお父さん', sumOfVotes: 8 },
    { rank: 55, characterName: 'トリスタン', sumOfVotes: 8 },
    { rank: 56, characterName: 'ルシア', sumOfVotes: 7 },
    { rank: 56, characterName: 'クイーン', sumOfVotes: 7 },
    { rank: 56, characterName: 'ルクレティア・メルセス', sumOfVotes: 7 },
    { rank: 56, characterName: 'エレノア・シルバーバーグ', sumOfVotes: 7 },
    { rank: 56, characterName: 'ワカバ', sumOfVotes: 7 },
    { rank: 56, characterName: 'シュン', sumOfVotes: 7 },
    { rank: 56, characterName: 'ディオス', sumOfVotes: 7 },
    { rank: 56, characterName: 'ベルナデット・イーガン', sumOfVotes: 7 },
    { rank: 56, characterName: 'ザジ・キュイロス', sumOfVotes: 7 },
    { rank: 56, characterName: 'スノウの腕', sumOfVotes: 7 },
    { rank: 57, characterName: 'ローレライ', sumOfVotes: 6 },
    { rank: 57, characterName: 'ザハーク', sumOfVotes: 6 },
    { rank: 57, characterName: 'フー・スー・ルー', sumOfVotes: 6 },
    { rank: 57, characterName: 'シメオン', sumOfVotes: 6 },
    { rank: 57, characterName: 'ゲンゲン', sumOfVotes: 6 },
    { rank: 57, characterName: 'コーネル', sumOfVotes: 6 },
    { rank: 57, characterName: 'アップル', sumOfVotes: 6 },
    { rank: 57, characterName: 'エイダ', sumOfVotes: 6 },
    { rank: 57, characterName: 'ユウ', sumOfVotes: 6 },
    { rank: 57, characterName: 'ムーイー', sumOfVotes: 6 },
    { rank: 57, characterName: 'チープー', sumOfVotes: 6 },
    { rank: 57, characterName: 'ししのはた', sumOfVotes: 6 },
    { rank: 57, characterName: 'タル', sumOfVotes: 6 },
    { rank: 57, characterName: 'ジェレミー', sumOfVotes: 6 },
    { rank: 57, characterName: 'イク', sumOfVotes: 6 },
    { rank: 58, characterName: 'アヤメ', sumOfVotes: 5 },
    { rank: 58, characterName: 'リムスレーア・ファレナス', sumOfVotes: 5 },
    { rank: 58, characterName: 'サイアリーズ', sumOfVotes: 5 },
    { rank: 58, characterName: 'クインシー', sumOfVotes: 5 },
    { rank: 58, characterName: 'エルンスト', sumOfVotes: 5 },
    { rank: 58, characterName: 'チャコ', sumOfVotes: 5 },
    { rank: 58, characterName: 'アマラリク', sumOfVotes: 5 },
    { rank: 58, characterName: 'ジョルジュ', sumOfVotes: 5 },
    { rank: 58, characterName: 'フレア', sumOfVotes: 5 },
    { rank: 58, characterName: 'カイ', sumOfVotes: 5 },
    { rank: 58, characterName: 'アイリ', sumOfVotes: 5 },
    { rank: 58, characterName: 'ララクル', sumOfVotes: 5 },
    { rank: 58, characterName: 'リィナ', sumOfVotes: 5 },
    { rank: 58, characterName: 'クロデキルド', sumOfVotes: 5 },
    { rank: 58, characterName: 'カレン', sumOfVotes: 5 },
    { rank: 58, characterName: 'ヴィルヘルム', sumOfVotes: 5 },
    { rank: 58, characterName: 'フレデグンド', sumOfVotes: 5 },
    { rank: 58, characterName: 'サナ', sumOfVotes: 5 },
    { rank: 58, characterName: 'ギゼル・ゴドウィン', sumOfVotes: 5 },
    { rank: 59, characterName: 'ユン', sumOfVotes: 4 },
    { rank: 59, characterName: 'ザヴィド', sumOfVotes: 4 },
    { rank: 59, characterName: 'ハンナ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ビュクセ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ディルク', sumOfVotes: 4 },
    { rank: 59, characterName: 'ベル', sumOfVotes: 4 },
    { rank: 59, characterName: 'フェリド', sumOfVotes: 4 },
    { rank: 59, characterName: 'レレイ', sumOfVotes: 4 },
    { rank: 59, characterName: 'リリィ・ペンドラゴン', sumOfVotes: 4 },
    { rank: 59, characterName: 'アンダルク・ベルグマン', sumOfVotes: 4 },
    { rank: 59, characterName: 'アドリアンヌ', sumOfVotes: 4 },
    { rank: 59, characterName: 'クロミミ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ロニー・ベル', sumOfVotes: 4 },
    { rank: 59, characterName: 'メルヴィス', sumOfVotes: 4 },
    { rank: 59, characterName: 'ツァウベルン', sumOfVotes: 4 },
    { rank: 59, characterName: 'ワタリ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ゼラセ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ミレイ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ブライト', sumOfVotes: 4 },
    { rank: 59, characterName: 'キャリー', sumOfVotes: 4 },
    { rank: 59, characterName: 'ミューラー', sumOfVotes: 4 },
    { rank: 59, characterName: 'ウィンディ', sumOfVotes: 4 },
    { rank: 59, characterName: 'ゴルドー', sumOfVotes: 4 },
    { rank: 59, characterName: 'ランディス', sumOfVotes: 4 },
    { rank: 59, characterName: 'ジェス', sumOfVotes: 4 },
    { rank: 59, characterName: 'ピリカ', sumOfVotes: 4 },
    { rank: 59, characterName: 'マリカ', sumOfVotes: 4 },
    { rank: 59, characterName: 'カットバニー', sumOfVotes: 4 },
    { rank: 60, characterName: 'アレニア', sumOfVotes: 3 },
    { rank: 60, characterName: 'シド', sumOfVotes: 3 },
    { rank: 60, characterName: 'ルオ・タウ', sumOfVotes: 3 },
    { rank: 60, characterName: 'アトリ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ヨシノ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ヒクサク', sumOfVotes: 3 },
    { rank: 60, characterName: 'リノ・エン・クルデス', sumOfVotes: 3 },
    { rank: 60, characterName: 'グリフィス', sumOfVotes: 3 },
    { rank: 60, characterName: 'ジーノ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ケンジ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ロディ', sumOfVotes: 3 },
    { rank: 60, characterName: 'フンギ', sumOfVotes: 3 },
    { rank: 60, characterName: 'カズラー', sumOfVotes: 3 },
    { rank: 60, characterName: 'エリン', sumOfVotes: 3 },
    { rank: 60, characterName: 'シュラ・ヴァルヤ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ミリオンアイ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ヨシュア・レーベンハイト', sumOfVotes: 3 },
    { rank: 60, characterName: 'ラマダ', sumOfVotes: 3 },
    { rank: 60, characterName: 'モクモク', sumOfVotes: 3 },
    { rank: 60, characterName: 'アイラ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ムーロ', sumOfVotes: 3 },
    { rank: 60, characterName: 'シャバック', sumOfVotes: 3 },
    { rank: 60, characterName: 'ロッテ', sumOfVotes: 3 },
    { rank: 60, characterName: 'マイク', sumOfVotes: 3 },
    { rank: 60, characterName: 'ナタリー', sumOfVotes: 3 },
    { rank: 60, characterName: 'ニナ', sumOfVotes: 3 },
    { rank: 60, characterName: 'トモ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ロラン・レザウルス', sumOfVotes: 3 },
    { rank: 60, characterName: 'リコ', sumOfVotes: 3 },
    { rank: 60, characterName: 'レギウス', sumOfVotes: 3 },
    { rank: 60, characterName: 'レオナ', sumOfVotes: 3 },
    { rank: 60, characterName: 'レパント', sumOfVotes: 3 },
    { rank: 60, characterName: 'コロク', sumOfVotes: 3 },
    { rank: 60, characterName: 'ブランド', sumOfVotes: 3 },
    { rank: 60, characterName: 'ペック（暗器使い）', sumOfVotes: 3 },
    { rank: 60, characterName: 'バベッジ', sumOfVotes: 3 },
    { rank: 60, characterName: 'スカルド・イーガン', sumOfVotes: 3 },
    { rank: 60, characterName: 'シスカ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ゲッシュ', sumOfVotes: 3 },
    { rank: 60, characterName: 'ノルデン', sumOfVotes: 3 },
    { rank: 60, characterName: 'ラハル', sumOfVotes: 3 },
    { rank: 60, characterName: 'ミーネ', sumOfVotes: 3 },
    { rank: 60, characterName: '霧の船の導者', sumOfVotes: 3 },
    { rank: 60, characterName: 'エッジ', sumOfVotes: 3 },
    { rank: 61, characterName: 'セイラ', sumOfVotes: 2 },
    { rank: 61, characterName: 'グランマイヤー', sumOfVotes: 2 },
    { rank: 61, characterName: 'アストリッド', sumOfVotes: 2 },
    { rank: 61, characterName: 'クーガ', sumOfVotes: 2 },
    { rank: 61, characterName: 'テレーズ・ワイズメル', sumOfVotes: 2 },
    { rank: 61, characterName: 'ネイ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ケリィ', sumOfVotes: 2 },
    { rank: 61, characterName: 'アナベル', sumOfVotes: 2 },
    { rank: 61, characterName: 'リューグ', sumOfVotes: 2 },
    { rank: 61, characterName: 'トラヴィス', sumOfVotes: 2 },
    { rank: 61, characterName: 'アルシュタート・ファレナス', sumOfVotes: 2 },
    { rank: 61, characterName: 'ソニア・シューレン', sumOfVotes: 2 },
    { rank: 61, characterName: 'ムルーン', sumOfVotes: 2 },
    { rank: 61, characterName: 'フランツ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ルロラディア', sumOfVotes: 2 },
    { rank: 61, characterName: 'バーバラ', sumOfVotes: 2 },
    { rank: 61, characterName: 'シルヴァ', sumOfVotes: 2 },
    { rank: 61, characterName: 'マクシミリアン', sumOfVotes: 2 },
    { rank: 61, characterName: 'カシム・ハジル', sumOfVotes: 2 },
    { rank: 61, characterName: 'ボブ', sumOfVotes: 2 },
    { rank: 61, characterName: 'アグネス', sumOfVotes: 2 },
    { rank: 61, characterName: 'アンネリー', sumOfVotes: 2 },
    { rank: 61, characterName: 'オボロ', sumOfVotes: 2 },
    { rank: 61, characterName: 'グレミオのシチュー', sumOfVotes: 2 },
    { rank: 61, characterName: 'シャドリ', sumOfVotes: 2 },
    {
      rank: 61,
      characterName: 'ジョセフィーヌ（ユマ・ヴァルヤ）',
      sumOfVotes: 2,
    },
    { rank: 61, characterName: 'シンロウ', sumOfVotes: 2 },
    { rank: 61, characterName: 'セルゲイ', sumOfVotes: 2 },
    { rank: 61, characterName: 'テスラ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ネイラ', sumOfVotes: 2 },
    { rank: 61, characterName: 'バズバ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ビーアーガ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ヒナ・アウマクア', sumOfVotes: 2 },
    { rank: 61, characterName: 'フヨウ', sumOfVotes: 2 },
    { rank: 61, characterName: 'ブラック', sumOfVotes: 2 },
    { rank: 61, characterName: 'マクシモス・ザフィール', sumOfVotes: 2 },
    { rank: 61, characterName: 'メルヴィル', sumOfVotes: 2 },
    { rank: 61, characterName: 'ルイス・キファーソン', sumOfVotes: 2 },
    { rank: 61, characterName: 'ロルフ・フラグ', sumOfVotes: 2 },
    { rank: 61, characterName: '幻想水滸伝6が発売される可能性', sumOfVotes: 2 },
    {
      rank: 61,
      characterName: '幻想水滸伝シリーズが移植される可能性',
      sumOfVotes: 2,
    },
    { rank: 61, characterName: '星辰剣', sumOfVotes: 2 },
    { rank: 62, characterName: 'ハスワール・ファレナス', sumOfVotes: 1 },
    { rank: 62, characterName: 'ミュラ', sumOfVotes: 1 },
    { rank: 62, characterName: 'タキ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ハルト', sumOfVotes: 1 },
    { rank: 62, characterName: 'ゴン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ハイ・ヨー', sumOfVotes: 1 },
    { rank: 62, characterName: 'コルネリオ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ノア', sumOfVotes: 1 },
    { rank: 62, characterName: 'キャザリー', sumOfVotes: 1 },
    { rank: 62, characterName: 'ヴァンサン・ド・ブール', sumOfVotes: 1 },
    { rank: 62, characterName: 'クロウリー', sumOfVotes: 1 },
    { rank: 62, characterName: 'ダイン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ミズラック', sumOfVotes: 1 },
    { rank: 62, characterName: 'デスモンド', sumOfVotes: 1 },
    { rank: 62, characterName: 'ツヴァイク', sumOfVotes: 1 },
    { rank: 62, characterName: 'ルル', sumOfVotes: 1 },
    { rank: 62, characterName: 'ビッチャム', sumOfVotes: 1 },
    {
      rank: 62,
      characterName: '創生の物語の『なみだ』を落とした「『やみ』」',
      sumOfVotes: 1,
    },
    { rank: 62, characterName: '山ねずみの紋章', sumOfVotes: 1 },
    { rank: 62, characterName: 'ラクジー', sumOfVotes: 1 },
    { rank: 62, characterName: 'デューク', sumOfVotes: 1 },
    { rank: 62, characterName: '水滸図', sumOfVotes: 1 },
    { rank: 62, characterName: 'コウユウ', sumOfVotes: 1 },
    { rank: 62, characterName: 'キアロ', sumOfVotes: 1 },
    { rank: 62, characterName: 'カゲ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ホウアン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ビッキー（小）', sumOfVotes: 1 },
    { rank: 62, characterName: 'ギジェリガー', sumOfVotes: 1 },
    { rank: 62, characterName: 'キリィ', sumOfVotes: 1 },
    { rank: 62, characterName: 'スロットマン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ビャクレン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ひいらぎこぞう', sumOfVotes: 1 },
    { rank: 62, characterName: 'スウ・ジン', sumOfVotes: 1 },
    { rank: 62, characterName: 'バルカス', sumOfVotes: 1 },
    { rank: 62, characterName: 'トーマ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ギルバート', sumOfVotes: 1 },
    { rank: 62, characterName: 'フーバー', sumOfVotes: 1 },
    { rank: 62, characterName: 'グントラム', sumOfVotes: 1 },
    { rank: 62, characterName: 'ディアドラ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ローガン', sumOfVotes: 1 },
    {
      rank: 62,
      characterName: '幻水4の話しかけると船に乗ってくれるラズリルの猫',
      sumOfVotes: 1,
    },
    { rank: 62, characterName: 'ベルフレイド', sumOfVotes: 1 },
    {
      rank: 62,
      characterName: '毎年この企画を運営していただいている幻水総選挙様',
      sumOfVotes: 1,
    },
    { rank: 62, characterName: 'サンスケ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ウェンデル', sumOfVotes: 1 },
    { rank: 62, characterName: 'アイリーン', sumOfVotes: 1 },
    { rank: 62, characterName: 'サンチェス', sumOfVotes: 1 },
    {
      rank: 62,
      characterName:
        '3でアラニスにいいカニパンチが取れたことを教えてくれるリザードのおじさん',
      sumOfVotes: 1,
    },
    { rank: 62, characterName: 'ルドン', sumOfVotes: 1 },
    { rank: 62, characterName: 'マリノ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ジョバンニ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ハレック', sumOfVotes: 1 },
    { rank: 62, characterName: 'ビアンカ', sumOfVotes: 1 },
    { rank: 62, characterName: 'リュセリ', sumOfVotes: 1 },
    { rank: 62, characterName: 'シロウ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ギョーム', sumOfVotes: 1 },
    { rank: 62, characterName: 'ミズキ', sumOfVotes: 1 },
    { rank: 62, characterName: 'バルバロッサ・ルーグナー', sumOfVotes: 1 },
    { rank: 62, characterName: 'イザベル', sumOfVotes: 1 },
    { rank: 62, characterName: 'ラウド', sumOfVotes: 1 },
    { rank: 62, characterName: 'レン・リイン', sumOfVotes: 1 },
    { rank: 62, characterName: 'ボッシュ', sumOfVotes: 1 },
    { rank: 62, characterName: 'ジョーカー（ワン）', sumOfVotes: 1 },
    { rank: 62, characterName: 'モンド', sumOfVotes: 1 },
    { rank: 62, characterName: 'グレアム・クレイ', sumOfVotes: 1 },
    { rank: 62, characterName: 'サーガン', sumOfVotes: 1 },
    { rank: 62, characterName: 'バランダン', sumOfVotes: 1 },
    { rank: 62, characterName: 'マーサ', sumOfVotes: 1 },
    { rank: 62, characterName: 'オロク', sumOfVotes: 1 },
    { rank: 62, characterName: 'ゼガイ', sumOfVotes: 1 },
    { rank: 62, characterName: 'イワノフ', sumOfVotes: 1 },
    { rank: 62, characterName: 'メイザース', sumOfVotes: 1 },
    { rank: 62, characterName: 'ポーラ', sumOfVotes: 1 },
    {
      rank: '殿堂入り',
      characterName: '幻水1主人公（坊ちゃん）',
      sumOfVotes: 127,
    },
  ]

  return data
}
